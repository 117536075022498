/**
 * @module framework/network/script-loader
 */

/**
 * Map of scripts which are loaded/loading
 * @hidden
 */
const loadedScriptMap: Map<string, Promise<void | Error>> = new Map();

/**
 * Loads a script asynchronously
 * @param url - url to script (prefer `https://`)
 * @param integrity - integrity hash to verify script
 * @param force - force load
 */
export async function loadScript(url: string, integrity?: string, force = false): Promise<void | Error> {
	if (!force && loadedScriptMap.has(url)) {
		return loadedScriptMap.get(url);
	}

	const promise = new Promise<void | Error>((resolve, reject) => {
		loadScriptCallback(
			url,
			(error) => {
				if (error) {
					reject(error);
					return;
				}

				resolve();
			},
			integrity
		);
	});

	loadedScriptMap.set(url, promise);

	return promise;
}

/**
 * Loads the script at the provided `src` and executes the callback
 * @hidden
 * @param url - url to script
 * @param callback
 * @param integrity - script integrity hash
 */
function loadScriptCallback(url: string, callback: (error?: Error) => void, integrity?: string): void {
	// Create Script Load object ASAP
	const js = document.createElement('script');
	js.src = url;

	if (integrity?.length) {
		js.setAttribute('integrity', integrity);
		js.setAttribute('crossorigin', 'anonymous');
	}

	// eslint-disable-next-line unicorn/prefer-add-event-listener
	js.onload = () => {
		callback();
	};

	// eslint-disable-next-line unicorn/prefer-add-event-listener
	js.onerror = () => {
		callback(new Error(`Failed to load script: '${url}'`));
	};

	// Load script (by appending to document head)
	// eslint-disable-next-line unicorn/prefer-dom-node-append
	document.head.appendChild(js);
}

/**
 * Ensures a script is loaded by checking for a global value
 * @param url - url to script (prefer `https://`)
 * @param global - global to check against
 * @param integrity - integrity hash to verify script
 */
export async function ensureLoadScript(url: string, global: string, integrity?: string): Promise<void | Error> {
	const loadedMessage = `Fallback has loaded for \`${global}\` from ${url}`;

	// @ts-expect-error Window is typed with any :-/
	if (window[global]) {
		return;
	}

	await loadScript(url, integrity, true);

	// @ts-expect-error Window is typed with any :-/
	if (window[global]) {
		console.info(loadedMessage);
		return;
	}

	console.warn(`Unable to find global \`${global}\`, retrying...`);

	// noinspection TailRecursionJS
	return ensureLoadScript(url, global, integrity);
}
